@import "../../styles/_chunk";

.PrivacyChecker {
  position: fixed;
  bottom: 0;
  z-index: 1100;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .PrivacyChecker__privacyContainer {
    position: relative;
    width: 540px;
    background-color: $white;
    padding: 0.75rem 1rem;
    text-align: center;
    font-weight: 400;
    color: $text-black;
    font-size: 15px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @include theme(light) {
      background: $grey-14;
      color: $text-white;
    }

    a {
      color: $primary-core;
    }

    .PrivacyChecker__closeBtn {
      position: absolute;
      top: 0;
      right: 0;
      color: $text-muted;
      background-color: $text-black;
      border-radius: 50%;
      padding: 0;
      font-size: 22px;
      border: 0;
      display: flex;
      margin-top: -5px;
      margin-right: -5px;

      svg {
        transform: scale(1.1);
      }
    }
  }
}
