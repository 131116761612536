@import "../../styles/_chunk";

$hpomen-white: #f7f7f7;
$xxlHeight: 260px;
$xlHeight: 220px;
$lgHeight: 200px;
$mdHeight: 190px;
$smHeight: 260px;
$xsHeight: 200px;

.OmenFooterBanner {
  display: block;
  background-color: $hpomen-white;
  padding: 0.5rem 0;

  @include media-breakpoint-up(sm) {
    background-image: linear-gradient(to right, $grey-16 50%, $hpomen-white 50%);
    padding: 0;
  }

  .OmenFooterBanner__siteContainer {
    position: relative;
    height: 100%;
    min-height: $xsHeight;
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-up(sm) {
      min-height: $smHeight;
    }

    @include media-breakpoint-up(md) {
      min-height: $mdHeight;
    }

    @include media-breakpoint-up(lg) {
      min-height: $lgHeight;
    }

    @include media-breakpoint-up(xl) {
      min-height: $xlHeight;
    }

    @include media-breakpoint-up(xxl) {
      min-height: $xxlHeight;
    }

    .OmenFooterBanner__details {
      z-index: 3;
      width: 100%;
      color: $grey-16;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(sm) {
        width: 240px;
        align-items: flex-end;
      }

      @include media-breakpoint-up(md) {
        width: 330px;
      }

      @include media-breakpoint-up(lg) {
        width: 420px;
      }

      @include media-breakpoint-up(xl) {
        width: 480px;
      }

      @include media-breakpoint-up(xxl) {
        width: 560px;
      }

      .OmenFooterBanner__details__logo {
        width: 130px;
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(sm) {
          width: 120px;
        }

        @include media-breakpoint-up(md) {
          width: 110px;
        }

        @include media-breakpoint-up(lg) {
          width: 120px;
        }

        @include media-breakpoint-up(xl) {
          width: 140px;
        }

        @include media-breakpoint-up(xxl) {
          width: 160px;
        }
      }

      .OmenFooterBanner__details__copy {
        font-size: 24px;
        line-height: 32px;
        font-family: 'Montserrat', 'Verdana', sans-serif;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 0.75rem;

        @include media-breakpoint-up(sm) {
          font-size: 26px;
          line-height: 34px;
          text-align: right;
        }

        @include media-breakpoint-up(md) {
          font-size: 20px;
          line-height: 30px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 26px;
          line-height: 34px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 30px;
          line-height: 40px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 38px;
          line-height: 44px;
          margin-bottom: 1rem;
        }
      }

      .OmenFooterBanner__details__btn {
        background-color: $black;
        font-family: 'Montserrat', 'Verdana', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 15px;
        color: $white;
        padding: 0.4375rem 2rem;

        @include media-breakpoint-up(sm) {
          font-size: 14px;
        }

        @include media-breakpoint-up(md) {
          font-size: 13px;
          padding: 0.4375rem 1.75rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 14px;
          padding: 0.4375rem 2rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 16px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 18px;
        }
      }
    }

    .OmenFooterBanner__background {
      display: none;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      @include media-breakpoint-up(sm) {
        display: block;
        width: 70%;
        margin-left: -40px;
      }

      @include media-breakpoint-up(md) {
        margin-left: -80px;
      }

      @include media-breakpoint-up(xl) {
        margin-left: -120px;
      }

      @include media-breakpoint-up(xxl) {
        width: 80%;
        margin-left: -260px;
      }

      .OmenFooterBanner__background__img {
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;

          @include media-breakpoint-up(md) {
            object-position: 0 -40px;
          }

          @include media-breakpoint-up(lg) {
            object-position: 0 -60px;
          }

          @include media-breakpoint-up(xl) {
            object-position: 0 -80px;
          }

          @include media-breakpoint-up(xxl) {
            object-position: 0 -115px;
          }
        }
      }

      .OmenFooterBanner__background__gradient {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100px;
        z-index: 2;
        background-image: linear-gradient(to right, $grey-16, transparent);
      }

      .OmenFooterBanner__background__arrowContainer {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        z-index: 2;

        .OmenFooterBanner__background__arrow {
          width: calc($smHeight / 4);

          @include media-breakpoint-up(md) {
            width: calc($mdHeight / 4);
          }

          @include media-breakpoint-up(lg) {
            width: calc($lgHeight / 4);
          }

          @include media-breakpoint-up(xl) {
            width: calc($xlHeight / 4);
          }

          @include media-breakpoint-up(xxl) {
            width: calc($xxlHeight / 4);
          }

          .OmenFooterBanner__background__arrow__top {
            height: 50%;
            width: 100%;
            background: linear-gradient(-70deg, rgba($hpomen-white, 0.75) 0%, rgba($hpomen-white, 0.75) 50%, transparent 50%, transparent 100%);

            @include media-breakpoint-up(md) {
              background: linear-gradient(-64deg, rgba($hpomen-white, 0.75) 0%, rgba($hpomen-white, 0.75) 50%, transparent 50%, transparent 100%);
            }
          }

          .OmenFooterBanner__background__arrow__bottom {
            height: 50%;
            width: 100%;
            background: linear-gradient(70deg, transparent 0%, transparent 50%, rgba($hpomen-white, 0.75) 50%, rgba($hpomen-white, 0.75) 100%);

            @include media-breakpoint-up(md) {
              background: linear-gradient(64deg, transparent 0%, transparent 50%, rgba($hpomen-white, 0.75) 50%, rgba($hpomen-white, 0.75) 100%);
            }
          }
        }

        .OmenFooterBanner__background__arrowGradient {
          width: calc($smHeight / 4);
          background-image: linear-gradient(to right, rgba($hpomen-white, 0.75), $hpomen-white);

          @include media-breakpoint-up(md) {
            width: calc($mdHeight / 4);
          }

          @include media-breakpoint-up(lg) {
            width: calc($lgHeight / 4);
          }

          @include media-breakpoint-up(xl) {
            width: calc($xlHeight / 4);
          }

          @include media-breakpoint-up(xxl) {
            width: calc($xxlHeight / 4);
          }
        }
      }
    }
  }
}
